@import '../../variables';

.identify {
	height:100vh;
	width:100vw;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	user-select: none;
	@media screen and (max-width:$mobile){
		align-items: flex-start;
		justify-content: center;
	}
	form {
		position: relative;
		margin-right: 40pt;
		margin-bottom: 50px;
		@media screen and (max-width:$vertical-display){
			margin-bottom: 0;
			margin-top: 50px;
			margin-right: 10px;
		}
		&:focus-within, &:hover {
			label {
				&::after {
					opacity:1;
				}
			}
		}
	}
	label {
		display: block;
		position: relative;
		z-index: 1;
		background:var(--color-primary);
		font-size: 36pt;
		cursor: pointer;
		outline:none;
		@media screen and (max-width:$vertical-display){
			font-size: 24pt;
		}
		&::after {
			display: inline-block;
			content:"";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color: #000000 transparent transparent transparent;
			vertical-align: middle;
			margin-left:.75ch;
			opacity:.3;
			transition:opacity $transition-speed;
		}
		img {
			vertical-align: baseline;
			height:100%;
			width:auto;
			transform:scale(1.3);
			margin-right: .75ch;
		}
		&:focus + ul {
			height:200px;
			background:rgba(255,255,255,0.3);
			border-color: black;
		}
	}
	ul {
		outline:none;
		position: absolute;
		width:100%;
		height:100%;
		top:calc(100% + 10px);
		left:0;
		cursor: pointer;
		list-style: none;
		font-size: 16pt;
		-webkit-overflow-scrolling: touch;
		overflow: auto;
		height:0;
		border:1px solid transparent;
		border-top-width: 5px;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;
		transition:all $transition-speed;
		background:none;
		&:focus-within {
			height:200px;
			background:rgba(255,255,255,0.3);
			border-color: black;
		}
	}
	li {
		padding:5px 15px;
		outline:none;
		transition:all $transition-speed;
		&:hover, &:focus {
			background:rgba(255,255,255,0.7);
			span {
				transform:rotate(720deg);
			}
		}
		span {
			position: relative;
			vertical-align: middle;
			margin-right: 1ch;
			display: inline-block;
			transition:transform $transition-speed*5;
			border-radius: 50%;
			border:1px solid black;
			overflow: hidden;
			&::after {
				content:"";
				position:absolute;
				top:0;
				left:0;
				height:100%;
				width:100%;
				background:var(--color-primary);
				opacity:.9;
				mix-blend-mode: color;
			}
		}
		img {
			height:35px;
			width:35px;
			border-radius: 50%;
			filter:grayscale(1);
			display: block;
		}
	}
	select {
		outline:none;
		position: absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		cursor: pointer;
	}
}