@import './variables';

:root {
  --color-primary:#{$color-primary};
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background:var(--color-primary);
  height:100vh;
  width:100vw;
  overflow: hidden;
  @media screen and (max-width:$vertical-display){
    height:auto;
    width:auto;
    overflow:visible;
	}
}

body, input, textarea, button {
  font-family: 'Dosis', sans-serif;
  font-weight: $weight-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-weight: $weight-semi-bold;
  outline:none;
}

button {
  cursor: pointer;
}

main {
  display: flex;
  height:100vh;
  overflow: hidden;
  @media screen and (max-width:$vertical-display){
    flex-direction: column;
    flex-wrap:wrap;
    height:auto;
    overflow: visible;
	}
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $weight-light;
}

::-webkit-input-placeholder {
  color:rgba(0,0,0,0.5);
  font-style: italic;
}
::-moz-placeholder {
  color:rgba(0,0,0,0.5);
  font-style: italic;
}
:-ms-input-placeholder {
  color:rgba(0,0,0,0.5);
  font-style: italic;
}
:-moz-placeholder {
  color:rgba(0,0,0,0.5);
  font-style: italic;
}

.empty {
  font-style: italic;
  opacity:.4;
  button {
    background:none;
    border:none;
    display: inline;
    font: inherit;
    text-decoration: underline;
    &:hover, &:focus {
      
    }
  }
}