@import '../../variables';

.menu {
	$padding-menu-vertical:14px;
	$padding-menu-horizontal:10px;
	flex: 0 0 auto;
	padding:$padding-menu-vertical 0;
	user-select: none;
	background:var(--color-primary);
	@media screen and (max-width:$vertical-display){
		flex:none;
		width:100%;
		position:sticky;
		top:0;
	}

	//bumps the menu above the expandable details section for the new links
	position: relative;
	z-index: 1;
	menu {
		height:100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@media screen and (max-width:$vertical-display){
			flex-direction: row;
		}
		@media screen and (max-width:$mobile){
			position: relative;
		}
	}
	.menu-group {
		@media screen and (max-width:$vertical-display){
			display: flex;
		}
	}
	.menu-group > li {
		position: relative;
		list-style: none;
		padding:0 $padding-menu-horizontal;
		&:focus-within, &:hover {
			div {
				opacity:1;
				pointer-events: auto;
				transform:translateX(0);
			}
			> button {
				opacity:.6;
			}
			@media screen and (max-width:$mobile){
				&:not(.color){
					position: static;
				}
			}
		}
		&::after {
			content:"";
			position: absolute;
			height:100%;
			width:8px;
			top:0;
			left:100%;
			transform:translateX(-50%) scale(1, 0);
			transform-origin: center center;
			background:black;
			border-radius:4px;
			opacity:0;
			transition:all $transition-speed*2 cubic-bezier(0.68, -0.55, 0.265, 1.55);
			z-index: 1;
			@media screen and (max-width:$vertical-display){
				height:8px;
				width:100%;
				left:0;
				top:calc(100% + 14px); //14 is padding on the menu
				transform:translateY(-50%) scale(1, 0);
			}
		}
		+ li {
			margin-top: 10px;
			@media screen and (max-width:$vertical-display){
				margin-top: 0;
			}
		}
		> button {
			outline:none;
			opacity:.3;
			transition:opacity $transition-speed;
			display: block;
			line-height: 1;
			padding:0;
			&:hover, &:focus {
				opacity:.6;
			}
		}
		&.active {
			&::after {
				transform:translateX(-50%) scale(1, 1);
				opacity:1;
				@media screen and (max-width:$vertical-display){
					transform:translateY(-50%) scale(1, 1);
				}
			}
			> button {
				opacity:1;
			}
		}
	}
	button {
		background:none;
		border:none;
	}
	img {
		width:100%;
		height:auto;
	}
	div {
		position: absolute;
		display: table;
		background:black;
		top:4px;
		left:100%;
		border-top-right-radius: 48px;
		font-size: 12pt;
		padding:2ch 36px .5ch 20px;
		color:white;
		font-weight: $weight-semi-bold;
		max-width:230px;
		opacity:0;
		transition:all $transition-speed*2;
		transform:translateX(-10px);
		pointer-events:none;
		z-index: -1;
		border-bottom:2px solid var(--color-primary);
		border-right:2px solid var(--color-primary);
		@media screen and (max-width:$vertical-display){
			top:calc(100% + 14px); //14 for menu padding
			left:0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 48px;
		}
	}
	.message, .form {
		white-space: nowrap;
		padding: 1ch 22px .6ch 15px;
	}
	.form {
		border-top-right-radius: 40px;
		padding: .5ch 14px .2ch 10px;
		width:100vw;
		max-width:300px;
		@media screen and (max-width:$vertical-display){
			border-top-right-radius: 0;
			border-bottom-right-radius: 40px;
		}
		form {
			display: flex;
			width:100%;
		}
		input {
			font-weight:$weight-semi-bold;
			padding:5px 10px;
			font-size: 10pt;
			margin:1ch 0;
			border-radius:0;
			-webkit-appearance: none;
			border:none;
			flex:1 1 100%;
			border-top-right-radius: 24px;
			background:#555;
			transition:background $transition-speed;
			@media screen and (max-width:$vertical-display){
				border-top-right-radius: 0;
				border-bottom-right-radius: 24px;
			}
			&:focus {
				background:white;
				&::-webkit-input-placeholder {
					color:rgba(0,0,0,0.5);
					font-style: italic;
				}
				&::-moz-placeholder {
					color:rgba(0,0,0,0.5);
					font-style: italic;
				}
				&:-ms-input-placeholder {
					color:rgba(0,0,0,0.5);
					font-style: italic;
				}
				&:-moz-placeholder {
					color:rgba(0,0,0,0.5);
					font-style: italic;
				}
			}
			&::-webkit-input-placeholder {
				color:rgba(255,255,255,0.5);
				font-style: italic;
			}
			&::-moz-placeholder {
				color:rgba(255,255,255,0.5);
				font-style: italic;
			}
			&:-ms-input-placeholder {
				color:rgba(255,255,255,0.5);
				font-style: italic;
			}
			&:-moz-placeholder {
				color:rgba(255,255,255,0.5);
				font-style: italic;
			}
		}
	}
	.filter-list {
		ul {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			width:230px;
		}
		li {
			margin-right: 2ch;
			margin-bottom: 1.5ch;
			&.active {
				button {
					opacity: 1;
					color:var(--color-primary);
				}
			}
		}
		button {
			color:white;
			background:none;
			border:none;
			font-size: 12pt;
			font-weight: $weight-semi-bold;
			outline:none;
			opacity:.5;
			transition:all $transition-speed;
			&:hover, &:focus {
				opacity: 1;
			}
		}
	}
	.color {
		position: relative;
		&:focus-within {
			button {
				opacity:.6;
			}
		}
		button {
			pointer-events: none;
			position: relative;
			z-index: 1;
		}
		input {
			position: absolute;
			top:0;
			left:0;
			height:100%;
			width:100%;
			opacity:0;
			cursor: pointer;
		}
	}
}