@import '../../variables';

.new {
	flex: 4 4;
	background:white;
	padding:40px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	position: relative;
	@media screen and (max-width:$vertical-display){
		flex:none;
		overflow: visible;
	}
	header {
		user-select: none;
		margin-bottom: 1.5em;
		h1 {
			font-size: 48pt;
			opacity:.7;
			line-height: 1;
			&:last-child {
				margin-bottom: .75em;
			}
		}
		h2 {
			background:black;
			color:white;
			margin-top: 2em;
			font-size: 14pt;
			text-transform: uppercase;
			font-weight: $weight-semi-bold;
			padding:5px 20px 5px 10px;
			border-top-right-radius: 25px;
			display: inline-block;
			border-bottom:2px solid var(--color-primary);
			border-right:2px solid var(--color-primary);
			button {
				background:none;
				border:none;
				margin-left: 2em;
				opacity:.4;
				outline:none;
				transition:all $transition-speed;
				&:hover, &:focus {
					transform:scale(1.1);
					opacity:1;
				}
				img {
					height:14px;
					width:14px;
				}
			}
		}
	}
	ul {
		list-style: none;
		display: flex;
		justify-content: space-between;
		align-items:stretch;
		flex-wrap: wrap;
		font-weight: $weight-semi-bold;
		margin:0 -10px; //accounts for padding on the outside of the list items
	}
	li {
		display: flex;
		flex-direction: column;
		flex:1 1 31%;
		margin-right: 15px;
		margin-bottom: 4em;
		position: relative;
		padding:10px;
		outline:none;

		//TODO try to add a transition of marginTop as well to help smooth out the changes;
		//will require JS changes, because the margin is included in that height
		transition:background $transition-speed*2;

		max-width: 50%; //prevents standalone [filtered] items from getting too big

		//see ".details" styles for explanation
		$whiteOverlay:rgba(255,255,255,0.92);
		$whiteOverlay2:rgba(255,255,255,1);
		background: linear-gradient($whiteOverlay2, $whiteOverlay2) no-repeat, linear-gradient($whiteOverlay2, $whiteOverlay2) no-repeat;

		@media screen and (max-width:$mobile){
			flex:0 0 100%;
			max-width: none;
			margin-bottom: 2em;
		}	
		
		&:focus, &:focus-within {
			background: linear-gradient($whiteOverlay, $whiteOverlay2) no-repeat, linear-gradient(#666, #666) no-repeat;
		}
		&.open {
			background: linear-gradient($whiteOverlay, $whiteOverlay2) no-repeat, linear-gradient(var(--color-primary), var(--color-primary)) no-repeat;
			&::after {
				opacity:.4;
			}
		}
		&.riley-favorite {
			h3::after {
				content:"";
				margin-left: .5ch;
				vertical-align: middle;
				display: inline-block;
				background:url(../../images/the-recommender.jpg);
				background-size:cover;
				height:25px;
				width:25px;
				border-radius:50%;
				transform:translateY(-2px); //2px offset for border visual weight
				border:1px solid var(--color-primary);
			}
		}
		&::after {
			content:"";
			position: absolute;
			top:calc(100% + 5px);
			left:0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 20px 0 0 20px;
			border-color: transparent transparent transparent var(--color-primary);
			opacity:0;
			transition: opacity .5s;
		}
		&.empty {
			width:100%;
			display: block;
			&:focus, &:focus-within {
				background:none;
			}
		}
	}
	h3 {
		font-weight: $weight-semi-bold;
		font-size: 18pt;
		margin-bottom: .5em;
		cursor: pointer;
	}
	.source {
		font-weight: $weight-light;	
		font-size: 12pt;
		user-select: none;
		line-height: 1.1;
	}
	a {
		font-weight: $weight-semi-bold;
		color:black;
		outline:none;
		transition:color $transition-speed;
		&:focus {
			color:var(--color-primary);
		}
	}
	.added {
		order:-1;
		text-transform: uppercase;
		opacity:.8;
		font-size: 9pt;
		margin-bottom: .25em;
		user-select: none;
	}
	.details-container {
		position: absolute;
		left:0;
		top:100%;
		width:100%;
		opacity:0;
		transition:top .5s, opacity 1s;
	}
	.details {
		display: flex;
		//TODO determine if this should be horizontally centered or not
		justify-content: flex-start;
		padding:30px;
		border-bottom: 6px solid var(--color-primary);
		
		//sneaky: this adds a white overlay on top of the existing color
		//elements can't have multiple background colors, but they can have multiple gradients
		//needed because the color variable will be in HEX format and therefore can't have hex
		//from: https://stackoverflow.com/a/41264329
		$whiteOverlay:rgba(255,255,255,0.9);
		background: linear-gradient($whiteOverlay, $whiteOverlay) no-repeat, linear-gradient(var(--color-primary), var(--color-primary)) no-repeat;
		//ideally, this should be set with an 8-digit hex code, but the result of the below
		//adds a space after the variable when rendering, thus eliminating this option
		// background: var(--color-primary)99;

		.content {
			display: flex;
			justify-content: center;
			width:100%;
			&.with-image {
				justify-content: flex-start;
			}
			@media screen and (max-width:$mobile){
				justify-content: space-between;
			}				
			img {
				width:200px;
				height:auto;
				margin-right:30px;
				align-self:flex-start;
			}
		}
		.note {
			max-width: 30%;
			margin-right:30px;
			margin-top: .25em;
			@media screen and (max-width:$mobile){
				max-width: none;
			}				
		}
		h4 {
			font-weight: $weight-semi-bold;
			text-transform: uppercase;
			font-size: 10pt;
			margin-bottom:.25em;
			user-select: none;
		}
		p {
			font-size: 12pt;
		}
		.actions {
			margin: .25em 0;
			flex:0 0 auto;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			user-select: none;
			@media screen and (max-width:$mobile){
				align-items: flex-end;
			}				
			a {
				text-decoration: none;
				background:var(--color-primary);
				text-transform: uppercase;
				font-weight: $weight-semi-bold;
				font-size: 18pt;
				text-align: center;
				padding:.25em 2em;
				color:white;
				display: block;
				margin-bottom: .5em;
				letter-spacing: 2px;
				transition:background $transition-speed*2;
				outline:none;
				&:hover, &:focus {
					background:black;
				}
			}
			label {
				display: block;
				margin-bottom: .5em;
				font-size: 10pt;
			}
			button {
				font-size: 10pt;
				background:none;
				border:none;
				border-bottom: 1px dashed #000;
				font-weight: $weight-semi-bold;
				outline:none;
				transition:all $transition-speed;
				&:hover, &:focus {
					color:var(--color-primary);
					border-bottom-color: var(--color-primary);;
				}
				&.hide {
					font-size: 8pt;
					border:none;
					font-weight: $weight-light;
					margin-top: auto;
					&:hover, &:focus {
						color:black;
						border:none;
						&::before { transform:translateX(-.5ch)}
						&::after { transform:translateX(.5ch)}
					}	
					&::before {
						display: inline-block;
						content:">";
						margin-right: .5ch;
						transition:transform $transition-speed;
					};
					&::after {
						display: inline-block;
						content:"<";
						margin-left: .5ch;
						transition:transform $transition-speed;
					};
				}
			}
		}
		.close {
			position: absolute;
			top:12px;
			right:12px;
			background:none;
			border:none;
			opacity:.4;
			transition: all $transition-speed*2 cubic-bezier(0.68, -0.55, 0.265, 1.55);
			outline:none;
			&:hover, &:focus {
				opacity:1;
				transform:scale(1.1);
			}
			img {
				width:18px;
				height:18px;
			}
		}
	}
}