@import '../../variables';

.viewed {
	flex: 2 2;
	background:black;
	color:white;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding:20px;
	@media screen and (max-width:$vertical-display){
		flex:none;
		overflow: visible;
	}
	header {
		user-select: none;
		margin-bottom: 2em;
		h1 {
			opacity:.7;
			font-size: 28pt;
			max-width: 5em;
			line-height: 1.1;
			@media screen and (max-width:$vertical-display){
				max-width: none;
			}
		}
	}
	ul {
		list-style: none;
		font-weight: $weight-semi-bold;
		font-size: 14pt;
		padding-right: 60px; //specified here to prevent link hovering in the dead space on the right
		@media screen and (max-width:$vertical-display){
			padding-right: 0;
		}
	}
	li {
		padding: 0 0 0 40px;		
		position: relative;
		margin-bottom: .75em;
		//focus-within has limited browser support
		&:focus-within, &:hover, &:focus {
			.favorite {
				transform:translateX(10px);
				opacity:1;
				&:hover, &:focus {
					transform:translateX(10px) scale(1.2);
				}		
			}
			footer {
				transition:opacity $transition-speed*4;
				opacity:1;
			}
		}
		&.empty {
			padding:0;
			font-size: 12pt;
		}
	}
	a {
		color:white;
		text-decoration: none;
		display: block;
		margin-bottom: .15em;
		outline:none;
		transition:color $transition-speed;
		line-height: 1.25;
		&:hover, &:focus {
			color:var(--color-primary);
		}
	}
	.favorite {
		overflow: hidden;
		background:none;
		border:none;
		height:45px;
		width:45px;
		position: absolute;
		top:0;
		left:-35px;
		opacity:.6;
		transition:all $transition-speed*2;
		outline:none;
		&:hover, &:focus {
			transform:scale(1.2);
		}
		svg {
			width:100%;
			height:auto;
		}
		#stroked, #filled {
			fill:var(--color-primary);
		}
		#filled {
			opacity:0;
			transform:scale(0);
			transform-origin: center center;
			transition:all $transition-speed*2;
		}
		&.favorited {
			#filled {
				opacity:1;
				transform:scale(1);
			}
		}
	}
	footer {
		font-weight: $weight-light;
		font-size: 10pt;
		color:rgba(white, .7);
		opacity:0;
		transition:opacity $transition-speed;
		cursor: default;
		user-select: none;
		button {
			outline:none;
			font-weight: $weight-semi-bold;
			background:none;
			border:none;
			color:rgba(white, .7);
			border-bottom: 1px dashed rgba(white, .7);
			font-size: 10pt;
			transition:all $transition-speed;
			&:hover, &:focus {
				color:var(--color-primary);
				border-bottom: 1px dashed var(--color-primary);
			}
		}	
	}
	time {

	}
}